body{
  background-color: #fff  !important;
}

@media (max-width: 767.98px) {
  .sidebar {
      top: 11.5rem;
      padding: 0;
  }
}

.navbar {
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .1);
}

@media (min-width: 767.98px) {
  .navbar {
      top: 0;
      position: sticky;
      z-index: 999;
  }
  .main {
    margin-left: 60% !important;
    width: 84% !important;
  }
}

.sidebar .nav-link {
  color: #fff;
  font-size: 18px;  /* Cambio el tamaño de los iconos*/
}
.sidebar .submenu .nav-link {
  font-size: 15px; /* Cambia el tamaño de los subiconos*/
}

.sidebar .nav-link.active {
  color: #fff;
  
  
}

.navbar.navbar.navbar-expand-lg {
  background-color: #114366 !important;
  color: white !important;
  position: sticky;  
}
.navbar-brand{
  color: white !important;
  font-weight: bold;
}

.na
.nav-item.dropdown a strong, .nav-item.dropdown a i{
  color: #fff !important
  
  
}

.navbar {
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .1);
  z-index: 100;
  
}
.sidebar{
  
}
.card{
 
  position: relative;
  background: transparent !important;
  backdrop-filter: blur(40px);
  border-radius: 1rem !important;
  flex-direction: column;
  padding: 1rem;
  
}

.card img{
  width: 100%;
}
.card h3{
  position: relative;
  top: 1rem !important;
  color: #fff;
}

.caja-login{
  margin-top: 45%;
}
.login-form{
  display: flex;
  left: 10rem;
}


.login-form,card{
  position: relative;
  top: 30%;
  left: 20%;
}

.d-grid .btn-primary{
  background: #ffbb55 !important;
}
.form-control{
  background: transparent !important;
  
}

.info-container {
  /* position: absolute;
  background-color: #fff;
  height: 100vh;
  width: 40%; */
  background-color: #fff;
  height: 100vh;

}
.info-container h1{
  
  /* position: absolute;
  top: 480px;
  margin-left: 20%; */
  
  
}
.texto-crisa{
  color: #ffbb55 ;
}

.proemsa-logo {
  position: relative;
  left: -256px;
  top: -200px;
  width: 400px;
  height: auto;
  border-radius: 10px;

}


.sidebar {
  position: fixed;
  top:0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 90px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  z-index: 99;
}

.main {
  margin-left: 12% !important;
  height: 100vh;
  width: 100% !important;
  background: black !important;
  border-radius: 10px;
  align-items: center;
  
}

.sidebar .nav i {
  margin-right: 15px;
}

footer{
  bottom: 0;
  position: fixed;
  background:#666;
  width: 84%;
  right: 0;
  color: #fff;
}


.breadcrumb-item {
  text-transform: capitalize;
}

::after,
::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

li {
    list-style: none;
}

h1 {
    font-weight: 600;
    font-size: 1.5rem;
}

body {
    font-family: 'Poppins', sans-serif;
}

ul.submenu {
  /* display: none; */
  height: 0;
  overflow: hidden;
  transition: all .7s;
  padding-left: 30px;
  &.expand {
    height: auto;
    transition: all .7s;
  }
}

.text-right{
  text-align: right;
}

.pdf-modal{
  height: 800px;
}

.pdf-modal #swal2-html-container {
  display: block;
  position: absolute;
  width: 93%;
  height: 750px;
}

.container.e-404 {
  width: 100%;
  background: #fff;
  margin: 10% auto;
  text-align: center;
  padding: 100px;
}

/* aqui inicia el login de inicio de sesion*/
.background-container {
  position: relative;
  width: 100%;
  height: 100vh; 
  background-image: url('/public/assets/images/fondo.jpeg') !important; 
  background-size: cover; 
  background-position: center; 
}

crisadatos {
  position: absolute;
  background: #fff;
  width: 4%;
  top: 0;
  left: 0;
  height: 10%;
  border-radius: 1%;
}

.crisadatos p {
  position: absolute;
  top: 50%;
  font-size: xx-large;
  left: 25%;
}
.crisa-logo {
  /* position: relative;
  top: 11rem;
  left: 12rem; */
  margin-top: 40%;
  width: 40%;
  
}

.caja-login .input-group input{
  color: #fff !important;
}

/*
Estilos del layout principal
*/

.icono-user i{
  position: relative;
  color: #fff;
  font-size: 8em;
  left: 30%;
  padding-block: 10%;

}

.icono-user .user{
  display: flex;
  color: #fff;
  left: -20px;
  align-items: center;
  position: relative;
  font-size: 2em;
  text-align: center;
  padding-left: 25%;
  
}

.fondo-menu{
  background-color: #114366;
  
}

.crisa-menu{
  position: relative;
  width: 3em;
  top: 14em !important;
  left: 11em !important;
  
}


.menu h5{
  position: relative;
  top: 17em !important;
  left: 20%;
  font-weight: bold;
  font-size: 1em !important;
  
  
}

/*
.home img{
  position: relative;
  left: 70em;
}
*/
.footer{
  background-color: #fff;
  border-top: 1px solid #ccc;
  position: fixed;
  bottom: 0px;
  height: 70px;
  padding-top: 20px;
}

/* Use pointer-events to prevent mouse interaction */
*:disabled {
  pointer-events: none;
}
  
  /* Use :disabled to change the appearance */
input:disabled, textarea:disabled, select:disabled {
  background: #dddddd !important;
  opacity: 0.5 !important;
}